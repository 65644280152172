const appConfig = {
  apiRoot: window.apiPrefix,
  apiPrefix: '/api',
  reportUrl: window.reportUrl,
  authenticatedEntryPath: '/app/inventory/catalog',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/app/account/kyc-form',
  locale: 'en',
  enableMock: true,
  version: window.appVersion,
  S3: window.S3,
  storage: window.storage,
  _version: '24.09.02'
}

export default appConfig
